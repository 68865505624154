import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./style.css";
import "./SharedPages.css"


const Home = () => {
  const services = [
    {
      title: "留学咨询",
      img: "/images/elements/service_1.jpg",
      content: "由留学领域资深专家为您提供优质专业的留学咨询和申请服务",
    },
    {
      title: "课程选择",
      img: "/images/elements/service_2.jpg",
      content: "针对学生的留学目的以及兴趣喜好量身定制课程选择",
    },
    {
      title: "职业发展",
      img: "/images/elements/service_3.png",
      content: "基于澳洲实时动态给与学生最专业的职业发展建议",
    },
    {
      title: "澳洲生活",
      img: "/images/elements/service_5.jpg",
      content: "为旅澳学生提供住宿以及寄宿家庭的选择",
    },
  ];

  const partners = [
    {
      ranking: 1,
      img: "/images/elements/Uni-MelU.jpg",
    },
    {
      ranking: 2,
      img: "/images/elements/Uni-UNSW.jpg",
    },
    {
      ranking: 3,
      img: "/images/elements/Uni-SYDU.jpg",
    },
    {
      ranking: 4,
      img: "/images/elements/Uni-ANU.jpg",
    },
    {
      ranking: 5,
      img: "/images/elements/Uni-MONASH.jpg",
    },
    {
      ranking: 6,
      img: "/images/elements/Uni-QLDU.jpg",
    },
    {
      ranking: 7,
      img: "/images/elements/Uni-UWA.jpg",
    },
    {
      ranking: 8,
      img: "/images/elements/Uni-ADU.jpg",
    },
  ];

  return (
    <>
      <div id="section2" init="true" className="section section2">
        <div className="wrap" style={{ textAlign: "center" }}>
          <div className="home_title">
            <a href="service/index.html" target="_blank">
              <img
                src="/images/elements/t_service.jpg"
                width="262"
                height="78"
                alt="服务范围"
              />
            </a>
          </div>
          <div className="service_text">小学 | 中学 | 大学 一站式服务</div>
          <div className="">
            <Row className="m-2 p-2 me-4">
              {services.map((service, index) => (
                <Col md={3} sm={6}>
                  <Card key={index} className="service_card border-0">
                    <Card.Img variant="top" src={service.img} />
                    <Card.Body>
                      <Card.Title>{service.title}</Card.Title>
                      <Card.Text>{service.content}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="c"></div>
          </div>
        </div>
        <div className="service_foot">
          站在学生的角度思考问题，与学生深入沟通，帮助学生找到最心意的学校，选择最佳的留学方案
        </div>
        <div className="service_foot_mobile">
          站在学生的角度思考问题，为学生量身定制留学方案
        </div>
      </div>

      <div id="section6" init="false" className="section section6">
        <div className="home_partner">
          <div className="home_partner_title">
            <a href="news/index.html" target="_blank">
              <img
                src="/images/elements/top8universities.jpg"
                alt="合作伙伴"
                width="372"
                height="73"
              />
            </a>
          </div>
          <div className="home_partner_text p-2">
            我们最大的使命就是为学生量身定制属于他们自己的留学计划，为学生的未来创造最大的可能性。
          </div>
          <div className="d-flex justify-content-center ">
            <Row className="m-2 p-2 partner_list">
              {partners.map((partner, index) => (
                <Col md={3} sm={4} xs={6} className="p-0 m-0">
                  <Card className="p-4">
                    <Card.Img variant="top" src={partner.img} />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
