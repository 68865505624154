import React from "react";
import "./style.css";
import { Col, Row } from "react-bootstrap";

const Footer = () => {
  const footerState = [
    {
      name: "维多利亚州",
      state: "state=vic",
    },
    {
      name: "新南威尔士州",
      state: "state=nsw",
    },
    {
      name: "昆士兰州",
      state: "state=qld",
    },
    {
      name: "西澳大利亚",
      state: "state=wa",
    },
  ];

  const homeServices = [
    {
      service: "大学选择",
      link: "/aus-universities",
    },
    {
      service: "学校申请",
      link: "/login",
    },
    {
      service: "职业发展",
      link: "/login",
    },
    {
      service: "澳洲生活",
      link: "/login",
    },
  ];
  return (
    <>
      <footer>
        <div className="contact">
          <div className="wrap">
            <Row>
              <Col lg={3} md={0} sm={0} className="d-none d-lg-block pl-4">
                <div className="home_about fl">
                  <dl className="mt-3">
                    <dt>
                      <a href="index.html">关于我们</a>
                    </dt>
                    <dd>
                      AudStudy专注于留学服务，始终追求“用最专业的服务为学生量身定制留学规划”。懂您所需、做您所想！
                    </dd>
                  </dl>
                </div>
              </Col>
              <Col lg={3} md={3} sm={3} xs={5}>
                <div className="home_case fl">
                  <h2>
                    <a href="/aus-universities">澳洲大学简介</a>
                  </h2>
                  <ul>
                    <li className="home_case_li">
                      {footerState.map((state, index) => (
                        <h5 key={state+index}>
                          <a href={`/aus-universities?${state.state}`}>
                            {state.name}
                          </a>
                        </h5>
                      ))}

                      <h5 className="mt-2">
                        <a href="/aus-universities" className="more">
                          查看更多 {">>"}
                        </a>
                      </h5>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={3} md={5} sm={5} className="d-none d-sm-block">
                <div className="home_service fl">
                  <h2>
                    <a href="/services">服务范围</a>
                  </h2>
                  {homeServices.map((service, index) => (
                    <a className="sub" href={service.link} key={service+index}>
                      {service.service}
                    </a>
                  ))}
                </div>
              </Col>
              <Col lg={3} md={4} sm={4} xs={7}>
                <div className="home_contact fl">
                  <h2 className="mb-0">
                    <a href="../contact/index.html" tppabs="#">
                      联系我们
                    </a>
                  </h2>
                  <ul>
                    <li>
                      <i></i>
                    </li>
                    <li>
                      <i className="weixin"></i>
                      <span>微 信： </span>AudStudy
                    </li>
                    <li>
                      <i className="email"></i>
                      <span>邮 箱： </span>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="mailto:admin@audstudy.com"
                      >
                        admin@audstudy.com
                      </a>
                    </li>
                    <li>
                      <i className="call"></i>
                      <span>电 话： </span>+61 426610525
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="blogroll">
          <p className="copyright">版权所有 AudStudy - 2024</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
