import React from "react";
import "./SharedPages.css"

const Header = () => {
  const handleClick = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    // <div>Header</div>
    <div className="wrap">
      <div className="fl logo_org">
        <a href="/" className="fr logo">
          <img
            src="/images/elements/AudStudy-logo.png"
            alt="澳达留学"
            width="100"
            height="auto"
          />
        </a>
      </div>
      <div className="fl logo_text logo_org">
        <a href="/" className="fr logo">
          <img
            src="/images/elements/logo_text.jpg"
            alt="懂你所需，做你所想"
            width="163"
            height="49"
            className="mt-2"
          />
        </a>
      </div>
      {/* logo Mobile  */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="fl logo_group_mobile ">
          <a href="/" className="fr logo">
            <img
              src="/images/elements/AudStudy-logo.png"
              alt="澳达留学"
              width="70"
              height="auto"
            />
          </a>
        </div>
        <div className="email_link_mobile logo_group_mobile ">
          <a className="email-link" href="mailto:admin@audstudy.com">
            <span className="email"></span>
            admin@audstudy.com
          </a>
        </div>
      </div>
      <div className="fr">
        <div className="top">
          <a className="email-link email_link_org" href="mailto:admin@audstudy.com">
            <span className="email"></span>
            admin@audstudy.com
          </a>
        </div>
        <nav className="nav ">
          <ul className=" navb">
            <li id="menu1">
              <a href="/">官网首页</a>
            </li>
            <li id="menu3">
              <a href="/aus-universities">澳洲大学</a>
            </li>
            <li id="menu4">
              <a href="/services">服务范围</a>
            </li>
            <li id="menu2">
              <a href="/login">学校申请</a>
            </li>
            <li id="menu2">
              <a href="/login">保险购买</a>
            </li>

            <li id="menu8">
              <p onClick={handleClick}>联系我们</p>
            </li>
          </ul>
          <span className="nav_icon"></span>
        </nav>
      </div>
    </div>
  );
};

export default Header;
