import React, { useState, useEffect } from "react";
import { uniDescriptions } from "./AusUniversitiesInfo";
import { Col, Row } from "react-bootstrap";
import "./SharedPages.css";

const AusUniversities = () => {
  const [selectedState, setSelectedState] = useState("WA");
  const [selectedStateUniversities, setSelectedStateUniversities] = useState(
    []
  );

  const highlightState = (stateId, svgDoc) => {
    resetAllHighlights(svgDoc);
    const elements = svgDoc.querySelectorAll(`[data-state-id="${stateId}"]`);
    elements.forEach((el) => {
      if (el.tagName.toLowerCase() === "path") {
        el.style.fill = "#4ea0ab";
      }
      if (el.tagName.toLowerCase() === "text") {
        el.style.fill = "#ffffff";
        el.style.fontWeight = "bold";
      }
    });
  };

  const resetAllHighlights = (svgDoc) => {
    const elements = svgDoc.querySelectorAll("[data-state-id]");
    elements.forEach((el) => {
      el.style.fill = "";
      el.style.fontWeight = "normal";
    });
  };

  const showUniInfo = (stateId) => {
    const universities = uniDescriptions[stateId] || [
      { name: "", description: "" },
    ];
    setSelectedStateUniversities(universities);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get("state")?.toUpperCase() || "WA";
    setSelectedState(state);
  }, [window.location.search]);

  const initializeSvgInteractions = (svgDoc) => {
    const stateElements = svgDoc.querySelectorAll("[data-state-id]");
    const handleStateClick = (event) => {
      const stateId = event.target
        .closest("[data-state-id]")
        ?.getAttribute("data-state-id");
      if (stateId) {
        window.history.pushState({}, "", `?state=${stateId.toLowerCase()}`);
        setSelectedState(stateId);
        showUniInfo(stateId);
        highlightState(stateId, svgDoc);
      }
    };
    stateElements.forEach((element) =>
      element.addEventListener("click", handleStateClick)
    );
  };

  return (
    <>
      <div>
        <div style={{ textAlign: "center", paddingTop: "10px" }}>
          <img
            className="AusUniMap_Logo"
            src="/images/elements/AusUniversities.jpg"
            alt="澳洲大学"
            width="372"
            height="73"
          />
        </div>
        <Row className="Map_Data_container">
          <Col className="">
            <object
              id="australia-map"
              className="australia-map"
              type="image/svg+xml"
              data="/images/elements/states.svg"
              aria-label="Australia Map"
              onLoad={(e) => {
                const svgDoc = e.target.contentDocument;
                if (svgDoc) {
                  initializeSvgInteractions(svgDoc);
                  showUniInfo(selectedState);
                  highlightState(selectedState, svgDoc);
                }
              }}
            ></object>
          </Col>

          <Col>
            <div className="AusUniMap_Box" id="info-box">
              {selectedStateUniversities.map((uni, idx) => (
                <div key={uni.name + idx}>
                  {uni.title && (
                    <h3 className="mb-4 mt-2 uni_title">{uni.title}</h3>
                  )}
                  <h5 className="uni_name mt-3">{uni.name}</h5>
                  <p className="uni_description mt-2">{uni.description}</p>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AusUniversities;
