import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";

const Services = () => {
  useEffect(() => {
    const cards = document.querySelectorAll(".service_card");

    const handleMouseEnter = (card) => {
      const blurShade = card.querySelector(".blur_shade");
      blurShade.style.top = "0";
      blurShade.style.opacity = "1";
      card.querySelector(".services_image").classList.add("blur");
    };

    const handleMouseLeave = (card) => {
      const blurShade = card.querySelector(".blur_shade");
      blurShade.style.top = "-100%";
      blurShade.style.opacity = "0";
      card.querySelector(".services_image").classList.remove("blur");
    };

    cards.forEach((card) => {
      card.addEventListener("mouseenter", () => handleMouseEnter(card));
      card.addEventListener("mouseleave", () => handleMouseLeave(card));
    });

    return () => {
      cards.forEach((card) => {
        card.removeEventListener("mouseenter", () => handleMouseEnter(card));
        card.removeEventListener("mouseleave", () => handleMouseLeave(card));
      });
    };
  }, []);

  const services = [
    {
      title: "课程选择",
      img: "/images/elements/s02.jpg",
      content:
        "一对一咨询 帮助学生选择心意的院校课程，为学生提供全方位咨询服务",
    },
    {
      title: "学校申请",
      img: "/images/elements/s03.jpg",
      content:
        "量身定制、创意无价 贴合学生自己的独一无二的申请意向书，帮助打开梦想学府的大门",
    },
    {
      title: "职业发展",
      img: "/images/elements/s03.jpg",
      content: "用数据和事实说话 基于澳洲实时动态给与学生最专业的职业发展建议",
    },
    {
      title: "澳洲生活",
      img: "/images/elements/s005.jpg",
      content: "提前安排 提前联系 为旅澳学生提供住宿以及寄宿家庭的选择",
    },
  ];

  const picBoxData = [
    {
      s: "1",
      group: 1,
      mobileVersion: "5",
      img1: "/images/elements/s11.jpg",
      title1: "申请材料准备与审核",
      img2: "/images/elements/s08.jpg",
      title2: "材料准备与审核",
      content1:
        "帮助学生准备留学申请所需的各类材料，包括个人陈述、推荐信等，确保每份文件都精准体现学生的优势。",
      content2:
        "专业的编辑团队将对材料进行细致的审核与润色，增强申请的竞争力。",
    },
    {
      s: "3",
      group: 3,
      mobileVersion: "5",
      img1: "/images/elements/s09.jpg",
      title1: "留学生活规划与适应指导",
      img2: "/images/elements/s10.jpg",
      title2: "留学生活规划与适应指导p",
      content1:
        "为初到澳洲的留学生，提供生活适应指导，帮助他们快速融入当地生活。",
      content2:
        "服务包括入境注意事项、以及落地必办事项等，让学生能更安心地在澳洲学习和生活。",
    },
    {
      s: "5",
      group: 2,
      mobileVersion: "5",
      img1: "/images/elements/s07.jpg",
      title1: "大学及专业深入解析",
      img2: "/images/elements/s12.jpg",
      title2: "大学及专业深入解析p",
      content1:
        "根据学生的兴趣和职业目标，对澳洲各大学及其热门专业的深入分析。",
      content2:
        "包括专业课程设置、以及毕业后的职业前景等，帮助学生做出更明智的选择",
    },
  ];

  const mobileVersionData = picBoxData.sort((a, b) => a.group - b.group);

  return (
    <>
      <div className="ser_project">
        <div className="home_title">
          <img src="/images/elements/s01.png" alt="留学服务" />
        </div>
        <Row className="p-2 ser_project_services">
          {services.map((service, index) => (
            <Col
              key={service + index}
              md={3}
              sm={3}
              xs={6}
              className="service_card_col"
            >
              <Card className="service_card border-0 m-2">
                <Card.Img
                  variant="top"
                  className="services_image"
                  src={service.img}
                />
                <Card.Body className="blur_shade">
                  <Card.Title className="blur_title">
                    {service.title}
                  </Card.Title>
                  <Card.Text className="text">{service.content}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      <div className="ser_scope">
        <div className="home_title">
          <img src="/images/elements/s06.png" alt="服务范围" />
        </div>
        <div className="bd">
          <ul className="picList">
            <li>
              <ul className="fix picBox">
                {picBoxData.map((picBox, index) => (
                  <div key={`picBoxGroup-${index}`} className={picBox.group}>
                    <li className={`s${Number(picBox.s)}`}>
                      <img src={picBox.img1} alt={picBox.title1} />
                      <div className="ser_info">
                        <p className="ser_info_title">{picBox.title1}</p>
                        <div className="ser_shade"></div>
                      </div>
                    </li>
                    <li className={`s${Number(picBox.s) + 1}`}>
                      <img src={picBox.img2} alt={picBox.title2} />
                      <p className="intro">
                        {picBox.content1}
                        <br />
                        {picBox.content2}
                      </p>
                    </li>
                  </div>
                ))}
              </ul>
              <ul className="picBox_mobile">
                {mobileVersionData.map((picBox, index) => (
                  <Row key={`picBoxGroupMobile-${index}`} className="m-4">
                    <Col md={6} sm={6} xs={12} className="m-0 p-0">
                      <div
                        className="services_mobile_title_box"
                      >
                        <img src={picBox.img1} alt={picBox.title1} />
                        <div
                          className="ser_info_mobile">
                          <h4 className="ser_info_title">{picBox.title1}</h4>
                          <div className="ser_shade_mobile"></div>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} sm={6} xs={12} className="m-0 p-0">
                      <div className="services_mobile_content_box">
                        <img src={picBox.img2} alt={picBox.title2} />
                        <p className="services_mobile_intro">
                          {picBox.content1}
                          <br />
                          {picBox.content2}
                        </p>
                      </div>
                    </Col>
                  </Row>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Services;
