export const uniDescriptions = {
    'NSW': [
        {
            title: '新南威尔士州:'
        },
        {
            name: 'The University of Sydney / 悉尼大学',
            description: '作为澳大利亚历史最悠久的大学，悉尼大学提供全面而多样的课程体系，尤其在建筑学和健康科学领域享有盛誉。'
        },
        {
            name: 'The University of New South Wales / 新南威尔士大学',
            description: '以其在科学、工程和医学领域的杰出研究和高毕业生就业率而著称。UNSW是澳大利亚领先的研究型大学之一，为学生提供了丰富的研究资源和实践机会。'
        },
        {
            name: 'University of Technology Sydney / 悉尼科技大学',
            description: '该校以推动科技创新和实用研究而闻名，与多个行业有着密切的合作关系，是追求技术和设计前沿教育的理想场所'
        },
        {
            name: 'Macquarie University / 麦考瑞大学',
            description: '麦考瑞大学在环境科学和语言学领域具有领先优势，提供高质量的教育和广泛的研究机会，帮助学生在全球范围内开展职业生涯。'
        },
        {
            name: 'Western Sydney University  / 西悉尼大学',
            description: 'WSU是一所动态的、以城市为焦点的机构，以其对健康、科学和影响城市环境的社会问题的研究而认可。大学提供广泛的本科和研究生课程，并深入参与该地区的社会和经济活动。'
        },
        {
            name: 'University of Newcastle / 纽卡斯尔大学',
            description: '以其强大的研究产出和实际创新重点而知名，纽卡斯尔大学提供多样的本科和研究生课程。该校特别在工程、医学和商业课程方面表现突出。'
        },
        {
            name: 'University of New England / 新英格兰大学',
            description: '位于新南威尔士州的阿米代尔，新英格兰大学以其优质的远程教育课程而著称。它提供广泛的艺术、科学、商业和法律课程，致力于为所有学生提供支持性的学习环境，无论是在校园内还是在线。'
        },
        {
            name: 'Southern Cross University / 南十字星大学',
            description: '该大学以其在环境科学和林业方面的优质课程而闻名，同时还提供健康、人文和商业等领域的稳固学位课程。南十字星大学致力于创新教学和研究，在新南威尔士州北部和昆士兰州南部设有校区。'
        },
        {
            name: 'Charles Sturt University / 查尔斯特大学',
            description: '以其实用和专业教育而著称，查尔斯特大学在农业、环境科学和教育领域表现突出。大学在新南威尔士州和维多利亚州拥有多个校区，便于广泛的学生群体就读。'
        },
    ],
    'VIC': [
        {
            title: '维多利亚州：'
        },
        {
            name: 'The University of Melbourne / 墨尔本大学',
            description: '作为澳大利亚顶尖的学府，以其在艺术和人文学科的卓越表现和研究创新而自豪，为学生提供了世界级的学术资源和研究平台。'
        },
        {
            name: 'Monash University / 莫纳什大学',
            description: '在国际化高等教育领域保持领先，尤其在工程和药学领域提供了顶尖的课程和研究机会。'
        },
        {
            name: 'Deakin University / 迪肯大学',
            description: '以其运动科学、商业和法律课程而闻名，注重实用技能和职业准备，为学生的职业成功奠定坚实基础。'
        },
        {
            name: 'RMIT University / 皇家墨尔本理工大学',
            description: '专注于技术和设计教育，与行业界限密切，提供实践中心的学习环境，助力学生未来职业发展。'
        },
    ],
    'QLD': [
        {
            title: '昆士兰州：'
        },
        {
            name: 'The University of Queensland / 昆士兰大学',
            description: '该校以其广泛的课程和卓越的研究成果而闻名全球，培养了多名诺贝尔奖得主，对全球科学研究和澳大利亚的政策制定产生了深远影响。'
        },
        {
            name: 'Queensland University of Technology / 昆士兰科技大学',
            description: 'QUT以其应用学科教育著称，尤其在商业和创意产业方面表现突出，致力于将理论教育与实际操作紧密结合。'
        },
        {
            name: 'Griffith University / 格里菲斯大学',
            description: '以艺术和音乐课程的深厚实力著称，并在环境科学研究方面取得显著成就，是艺术与科学交叉融合的教育先锋。'
        },
        {
            name: 'Bond University / 邦德大学',
            description: '邦德大学以其个性化的教学和小班授课模式闻名，提供广泛的本科和研究生课程。学校特别注重实践经验和国际视野，培养学生的全球竞争力。'
        },
    ],
    'WA': [
        {
            title: '西澳大利亚州：'
        },
        {
            name: 'The University of Western Australia / 西澳大学',
            description: '西澳大学是西澳大利亚州的第一学府，特别在资源和材料工程领域的研究成就全球领先。大学致力于推动科学创新与实用技术的结合，培养学生解决复杂工程问题的能力。'
        },
        {
            name: 'Curtin University / 科廷大学',
            description: '以其先进的矿物和采矿工程课程著称于世，科廷大学不仅提供深厚的学术基础，还重视实际操作和现场经验，为学生打开通向全球矿业领域的大门。'
        },
        {
            name: 'Murdoch University / 莫道克大学',
            description: '该大学在兽医科学和生物技术领域表现卓越，提供了广泛的研究机会和实践平台，帮助学生在这些迅速发展的行业中脱颖而出。'
        },
        {
            name: 'Edith Cowan University / 埃迪斯科文大学',
            description: '该大学在兽医科学和生物技术领域表现卓越，提供了广泛的研究机会和实践平台，帮助学生在这些迅速发展的行业中脱颖而出。'
        },
    ],
    'SA': [
        {
            title: '南澳大利亚州：'
        },
        {
            name: 'The University of Adelaide / 阿德莱德大学',
            description: '在葡萄酒和食品、健康科学以及工程技术方面进行持续创新，为学生提供了丰富的研究和实践机会。'
        },
        {
            name: 'University of South Australia / 南澳大学',
            description: '特别在技术和社会科学领域与行业的紧密结合著称，为学生提供与行业需求同步的教育资源。'
        },
        {
            name: 'Flinders University / 弗林德斯大学',
            description: '以其医学和创意艺术课程的深度与行业的合作闻名，提供了丰富的实践机会和专业发展路径。'
        },
    ],
    'ACT': [
        {
            title: '澳大利亚首都领地：'
        },
        {
            name: 'The Australian National University / 澳大利亚国立大学',
            description: '位于澳大利亚首都堪培拉，长期占据澳大利亚高校排名榜首。ANU以其在政治学、物理学和社会科学研究领域的卓越成就而闻名于世，是追求学术研究卓越的理想之选。'
        },
        {
            name: 'University of Canberra / 堪培拉大学',
            description: '该大学以其实用性强的职业教育课程著称，提供多样化的专业课程，并致力于通过实践学习培养学生的职业技能。高就业率展示了其与行业的紧密联系和教育质量。'
        },
    ],
    'TAS': [
        {
            title: '塔斯马尼亚州：'
        },
        {
            name: 'The University of Tasmania / 塔斯马尼亚大学',
            description: '位于风景独特的塔斯马尼亚，UTAS在环境和海洋科学研究方面处于世界领先地位，提供顶尖的医学和健康科学课程。该大学致力于推动可持续发展，并对全球和本地社区产生深远的正面影响。'
        },
    ],
    'NT': [
        {
            title: '北领地州：'
        },
        {
            name: 'Charles Darwin University / 查尔斯达尔文大学',
            description: '位于北领地，以其在土著和热带健康、环境科学和能源研究方面的成就而知名。该大学致力于服务当地社区，同时保持广阔的国际视野，使其在澳大利亚大学中独树一帜。'
        },
    ]

};