import React from "react";

const Login = () => {
  return (
    <div class="d-flex justify-content-center m-5">
      <div class="login_card card shadow border-0 mt-5 mb-5">
        <div class="card-body p-4">
          <div class="row">
            <div class="col-md-12">
              <section>
                <form id="account" method="post">
                  <h3 class="border-bottom pb-3 mb-4 text-secondary text-center">
                    请登录账号
                  </h3>
                  <div class="text-danger" role="alert"></div>
                  <div class="form-floating mb-3">
                    <input
                      class="form-control"
                      autocomplete="username"
                      aria-required="true"
                      placeholder="name@example.com"
                    />
                    <label class="form-label">Email</label>
                    <span class="text-danger"></span>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                      class="form-control"
                      autocomplete="current-password"
                      aria-required="true"
                      placeholder="password"
                    />
                    <label class="form-label">Password</label>
                    <span class="text-danger"></span>
                  </div>
                  <div class="checkbox mb-3">
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    /> {" "}
                    <label for="vehicle1">Remember Me</label>
                  </div>
                  <div>
                    <button
                      id="login-submit"
                      type="submit"
                      class="w-100 btn btn-lg AS_blue_btn"
                    >
                      登录
                    </button>
                  </div>
                  <div class="d-flex justify-content-between pt-2"></div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
